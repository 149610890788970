import React from "react";
import "../index.scss";
import { Link } from "react-router-dom";

function EventBanner() {
  return (
    <div className="event-banner relative h-auto pb-[15rem] py-[4rem] w-[100%]  flex flex-col items-center justify-center ">
      <div className="w-[90%] md:w-[650px] text-center mx-auto">
        <h1 className="text-[1.7rem] xm:text-[3rem] text-[white] font-montserrat font-[600]">
          Eventet
        </h1>
        <h1 className="text-[2rem] xm:text-[3rem] text-[orangered] font-montserrat font-[700] italic">
          26 - 27 Tetorë 2024
        </h1>
        <p className="text-[1rem] xm:text-[1.4rem] text-[white] font-montserrat font-[400] pt-[8px]">
          "Do mbahet eventi i radhës, edicioni i dytë i Downhill Cup"
        </p>
        <div className="w-full flex flex-col sm:flex-row items-center justify-center gap-[1.5rem] my-[2.5rem]">
          <Link to={"/eventet"}>
            <h1 className="button w-[170px] py-[0.6rem] rounded-[5px] text-[white] bg-[orangered] duration-200 border-2 border-[orangered] font-[500] text-[18px]">
              Mëso më shumë
            </h1>
          </Link>
          <span className="button w-[170px] py-[0.6rem] rounded-[5px] hover:text-[orangered] text-[white] hover:bg-[white] duration-200 border-2 border-[white] font-[500] text-[18px]">
            <a
              target="#blank"
              href="https://l.facebook.com/l.php?u=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSfi9e6uXgB8ikPUnwxQlvRNI8Y5IqE_rrjATNs1iiFC43ZYlA%2Fviewform%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR0m3H3feOMkcv3frB4nip290RAhHyntdAR_Xfr2RoHuIpt5HwyR6dxBhho_aem_Zz9ILyQYKB49dVpAsT56QA&h=AT3c-2-rVVozksZyzjvcMcFRQJk1eFy0a53vt1dbRonibmXcC870QTwRwE6aVKdwkgS_94zsey8FWIerozcH0IzdMnpfv6XpVjw6YmRa8FIIFG-R69EwAd6loQKWbHPwN7y7tQ"
            >
              Regjistrohu
            </a>
          </span>
        </div>
      </div>
      <div className="absolute -bottom-[10rem] xm:-bottom-[7rem] mx-auto  w-[95%] h-auto  lg:w-[90%] xl:w-[1200px] flex items-center justify-center gap-[0rem] flex-wrap xm:flex-nowrap">
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/galeri/3.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/12.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/galeri/4.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default EventBanner;
